<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Add Inventory Area">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">
    
                <b-row>
                    <b-col md="6">
                        <b-form-group
                        label="Area Name"
                        class="required legend_top_space_mobile"
                        >
                        <b-form-input
                            placeholder="2 to 50 characters"
                            v-model="form.name"
                            autocomplete="off"
                        />
                        </b-form-group>
                    </b-col>
   
                
                  <b-col md="6">

                        <b-form-group
                          label="Area Manager"
                          class="required"
                        >
                      
                          <v-select
                            v-model="form.user"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="full_name"
                            :options="users"
                            placeholder="Select"
                          />
                        </b-form-group>
                      </b-col>
                </b-row>

                <b-row>
                  <b-col md="12">
                        <!-- <b-form-group
                          label="Project Sites"
                          class=""
                        >
                        
                          <v-select
                            v-model="form.sites"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="site_name"
                            multiple
                            :options="sites"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                          />
                        </b-form-group> -->

                        <div class="px-2">

                          <b-row class="py-1">
                              <!-- Per Page -->
                              <b-col cols="12" md="10" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                                  <label>Show</label>
                                  <v-select
                                      v-model="perPage"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="perPageOptions"
                                      :clearable="false"
                                      class="per-page-selector d-inline-block mx-50"
                                      @input="updatePagination"
                                      style="width: 86px;"
                                  />
                                  <label>entries</label>
                              </b-col>
                              <b-col cols="12" md="2" class="">
                                  <label class="label-space float-right mt-1"> Total Selected QR - {{ total_selected }} </label>
                              </b-col>
  
                          </b-row>

                          <hr>

                          <b-row v-if="sites.length > 0" class="">

                              <div class="mb-2">
                                  <div class="card-deck custom-card qrCard" v-for="(item,index) in items_paged" :key="index" style="margin-top: 20px !important; margin-bottom:  0 !important;">
                                      
                                      <div class="card box-shadow border h-100" :class="item.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(item._id)" v-if="!item.pre_selected">

                                          <p class="p-1 categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;" :title="item.site_name.length > 20 ? item.site_name : ''"> 
                                            <!-- <span class="bg-secondary h5 rounded-circle wordBreak text-white" style="padding: 2px; width: 50px !important; height: 50px !important;">{{ getInitials(item.site_name) }}</span> -->
                                            <b-img-lazy :src="item.logo_thumbnail" class="rounded-circle" v-if="item.logo_thumbnail != null" height="30" width="30" />

                                            <span v-if="item.logo_thumbnail == null" height="30" width="30">
                                                <feather-icon icon="MapPinIcon" style="height: 28px; width: 28px;"/>
                                            </span>
                                            
                                            <span v-b-tooltip.hover.v-warning :title="item.site_name"> {{ item.site_name | truncate11 }} </span>
                                          </p> 

                                      </div>
                                      
                                      <div class="card box-shadow border h-100 border-success" v-else>

                                          <p class="p-1 categoryH5 pb-0 mb-0 text-secondary" style="font-size: 11px;" :title="item.site_name.length > 20 ? item.site_name : ''"> 
                                            <!-- <span class="bg-secondary h5 rounded-circle wordBreak text-white" style="padding: 2px; width: 50px !important; height: 50px !important;">{{ getInitials(item.site_name) }}</span> -->
                                            <b-img-lazy :src="item.logo_thumbnail" class="rounded-circle" v-if="item.logo_thumbnail != null" height="30" width="30" />

                                            <span v-if="item.logo_thumbnail == null" height="30" width="30">
                                                <feather-icon icon="MapPinIcon" style="height: 28px; width: 28px;"/>
                                            </span>
                                            
                                            <span v-b-tooltip.hover.v-warning :title="item.site_name"> {{ item.site_name | truncate11 }} </span>
                                          </p> 

                                      </div>

                                  </div>

                              </div>
                              
                          </b-row>
                          <div v-else>
                              <p class="text-center h5"> No Sites Found. </p>
                          </div>
                          </div>

                          <hr>

                          <div class="mx-2 mb-2">
                          <b-row>

                          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                          </b-col>
                          <!-- Pagination -->
                          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            @input="updatePagination"
                          >
                            <template #prev-text> <feather-icon icon="ChevronLeftIcon" size="18"/></template>
                            <template #next-text> <feather-icon icon="ChevronRightIcon" size="18"/></template>
                          </b-pagination>

                          </b-col>

                          </b-row>
                          </div>

                      </b-col>
                </b-row>

                
                
                <b-row class="mt-2">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb,BPagination,VBTooltip,BImgLazy
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select'
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect,
        BPagination,
        BImgLazy
      },
      directives: {
        Ripple,
      },
      directives: {
        Ripple,
        'b-tooltip': VBTooltip
      },

      data() {
        return {
          error_message:null,
          showDismissibleAlert:false,
          sites:[],
          users:[],
          form :{
            name: '',
            user:null,
            sites:[]
          },
          total_selected : 0,

          items_paged : [],
          perPageOptions : [7, 28, 56, 112],
          perPage : 28,
          totalRecords : 0,
          currentPage : 1,
          searchQuery : '',
          from : 0,
          to : 0,
        }
      },

      methods : {
        getInitials(fullName){
          const words = fullName.split(" ");
  
          let initials = "";

          for (let i = 0; i < Math.min(words.length, 2); i++) {
            const word = words[i];
            if (word.length > 0) {
              initials += word[0].toUpperCase();
            }
          }
          return initials;
        },

        getCount(){
          this.total_selected = this.sites.filter(obj => obj.selected === true).length;
        },

        updatePagination(){
          this.items_paged = [];
          this.from = null;
          this.to = null;
          
          for (var i = (this.currentPage - 1) * this.perPage; i < (this.currentPage * this.perPage) && i < this.sites.length; i++) {
            this.items_paged.push(this.sites[i]);
          }

          this.perPage = this.perPage;
          this.from = this.items_paged.length > 0 ? (this.perPage * (this.currentPage -1)) + 1 : 0;
          this.to = (this.perPage * (this.currentPage -1)) + this.items_paged.length;
          this.totalRecords = this.sites.length;
        },

        updateSelect(_id){
            this.sites.forEach((el, index) => {
                if(el._id == _id){
                    this.sites[index].selected = this.sites[index].selected == true ? false : true;
                }
            });
            this.getCount();
        },
    
        formSubmit(e){
          e.preventDefault();
          
          this.form.sites = this.sites.filter(item => item.selected === true);

          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/add-inventory-area'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Added Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'inventory-area'})
                    });
                    
                }
            });
          
        },
    
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'inventory-area'},
            text: 'Area Management',
          },{
            to:null,
            text: 'Add Area Management',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name:'inventory-area'})
        },
        
        getUsers() {
          return this.$store.dispatch(POST_API, {
            data: {
              
            },
            api: '/api/all-inventory-area-users3'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.users = this.$store.getters.getResults.data;
                
                return this.users;
              }
            });
        },

        getInventoryAreaSites() {
          return this.$store.dispatch(POST_API, {
            data: {

            },
            api: '/api/all-inventory-area-sites'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.sites = this.$store.getters.getResults.data;

                return this.sites;
              }
            });
        },
        
        
    
      },
      mounted(){
        this.getUsers();
        this.getInventoryAreaSites().then(()=>{
          this.updatePagination();
        });
      }
    }
    </script>

    <style lang="scss">
      @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    
<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    .border-danger {
        border: 2px solid #ea5455 !important;
        padding: 2px !important;
    }
    .border-secondary {
        border: 2px solid rgb(221, 221, 221) !important;
        padding: 2px !important;
    }
    .border-success {
        border: 2px solid #28a745 !important;
        padding: 2px !important;
    }
    .card-body{
        padding-top: 1rem !important;
    }
    .label-space{
        margin-left: 10px !important;
    }
</style>
